<template>
  <div>
    <transition-slide mode="out-in">
      <div
        v-if="showDaySelector"
        class="day-selector-container"
      >
        <day-selector
          :current-day="currentDay"
          :day-progress="dayProgress"
          :interval="interval"
          :weeks="weeks"
          @dayChosen="changeDay"
        />
      </div>
    </transition-slide>

    <h1 class="program-h1">
      <template v-if="status == 'loaded'">
        {{ day.title }}
      </template>
      <template v-else-if="status == 'loading'">
        <span>Loading <spinner color="var(--primary)" /></span>
      </template>
      <template v-else-if="status == 'error'">
        Error
      </template>
      <template v-else-if="status == 'no_access'">
        Thank You
      </template>
    </h1>

    <transition-slide mode="out-in">
      <div
        v-if="saveError"
        class="save-error"
      >
        <b-alert
          variant="danger"
          show
        >
          <p>Unable to save your progress.</p>

          <b-button
            size="sm"
            variant="light"
            @click="save"
          >
            Try Again
          </b-button>
        </b-alert>
      </div>
    </transition-slide>

    <div
      v-if="status == 'loaded'"
      class="page-container"
    >
      <div
        v-for="(page, pageIndex) of pages"
        :key="`page${pageIndex}`"
        :ref="`page${day.number}-${pageIndex}`"
        class="page"
      >
        <div class="page-content">
          <div
            v-for="(pageContent, pageContentIndex) of page.content"
            :key="`pageContent${pageContentIndex}`"
          >
            <template v-if="['text_single_line', 'text_multi_line'].includes(pageContent.content_type)">
              <b-form-group
                :label="pageContent.content_data.label"
                :label-for="`label${pageIndex}-${pageContentIndex}`"
                label-class="h2"
              >
                <page-content-help
                  v-if="pageContent.content_data.help_text !== ''"
                  :modal-id="`modal${pageIndex}-${pageContentIndex}`"
                  :page-content="pageContent"
                />

                <b-form-input
                  v-if="pageContent.content_type == 'text_single_line'"
                  :id="`label${pageIndex}-${pageContentIndex}`"
                  v-model="pageContent.user_response"
                  type="text"
                  placeholder="Type your answer here"
                />
                <b-form-textarea
                  v-else
                  :id="`label${pageIndex}-${pageContentIndex}`"
                  v-model="pageContent.user_response"
                  rows="3"
                  placeholder="Type your answer here"
                />
              </b-form-group>
            </template>
            <template v-else-if="pageContent.content_type == 'scale'">
              <h2>{{ pageContent.content_data.label }}</h2>

              <page-content-help
                v-if="pageContent.content_data.help_text !== ''"
                :modal-id="`modal${pageIndex}-${pageContentIndex}`"
                :page-content="pageContent"
              />

              <div class="scale">
                <b-form-radio
                  v-model="pageContent.user_response"
                  :name="`radio${pageIndex}-${pageContentIndex}`"
                  value="1"
                >
                  1
                </b-form-radio>

                <b-form-radio
                  v-model="pageContent.user_response"
                  :name="`radio${pageIndex}-${pageContentIndex}`"
                  value="2"
                >
                  2
                </b-form-radio>

                <b-form-radio
                  v-model="pageContent.user_response"
                  :name="`radio${pageIndex}-${pageContentIndex}`"
                  value="3"
                >
                  3
                </b-form-radio>

                <b-form-radio
                  v-model="pageContent.user_response"
                  :name="`radio${pageIndex}-${pageContentIndex}`"
                  value="4"
                >
                  4
                </b-form-radio>

                <b-form-radio
                  v-model="pageContent.user_response"
                  :name="`radio${pageIndex}-${pageContentIndex}`"
                  value="5"
                >
                  5
                </b-form-radio>

                <p class="scale-label">
                  Poor
                </p>

                <p class="scale-label">
                  Excellent
                </p>
              </div>
            </template>
            <div
              v-else-if="pageContent.content_type == 'video'"
              class="video-container"
            >
              <iframe
                :key="`video${videoKeySuffix}`"
                :src="`https://player.vimeo.com/video/${pageContent.content_data.video_id}`"
                frameborder="0"
                webkitAllowFullScreen
                mozallowfullscreen
                allowFullScreen
              />
            </div>
            <div
              v-else-if="pageContent.content_type == 'other'"
              v-html="pageContent.content_data.other_content"
            />
          </div>

          <button
            v-if="pageIndex < maxPageIndex"
            class="btn btn-lg"
            @click="changePage('next')"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
    <div
      v-else-if="status == 'error'"
      class="content"
    >
      <div
        class="alert alert-danger"
        role="alert"
      >
        <p>
          Unable to communicate with the server. Please check your connection
          and try again.
        </p>

        <button
          type="button"
          class="btn btn-light"
          @click="loadData"
        >
          Try Again
        </button>
      </div>
    </div>
    <div
      v-else-if="status == 'no_access'"
      class="content"
    >
      Thank you for participating in the program. This iteration of the program
      has ended. You still have access to your journal.
    </div>
    <div :class="'bottom-bar' + (status == 'loaded' ? '' : ' hidden') + (showDaySelector ? ' show-day-selector' : '')">
      <template v-if="status == 'loaded'">
        <div class="progress-data">
          <button
            class="btn btn-lg"
            @click="showDaySelector = !showDaySelector"
          >
            <img
              src="../../assets/icons/up-arrow.svg"
              alt
              class="icon"
            >
            <span>My Overall</span> Progress
          </button>

          <div class="progress-today">
            <div class="progress-today-top">
              <span>{{ interval === 'daily' ? 'Today\'s' : 'This week\'s' }} progress</span>
              <span class="progress-today-percentage">{{ day.percent_complete }}%</span>
            </div>

            <b-progress
              :value="day.percent_complete"
              :max="100"
              variant="light"
            />
          </div>
        </div>

        <button
          class="day"
          @click="showDaySelector = !showDaySelector"
        >
          <span>{{ interval === 'daily' ? 'Day' : 'Week' }}</span>
          {{ day.number }}
        </button>

        <div class="nav-and-save-buttons">
          <button
            :disabled="activePageIndex == 0 || leavingPageIndex"
            class="btn btn-lg"
            @click="changePage('previous')"
          >
            <img
              src="../../assets/icons/chevron-up.svg"
              alt
              class="icon"
            >
            <span>Previous</span>
          </button>

          <button
            :disabled="activePageIndex == maxPageIndex || leavingPageIndex"
            class="btn btn-lg"
            @click="changePage('next')"
          >
            <img
              src="../../assets/icons/chevron-down.svg"
              alt
              class="icon"
            >
            <span>Next</span>
          </button>

          <button
            :disabled="saving"
            class="btn btn-lg save-button"
            @click="save"
          >
            <template v-if="saving">
              <spinner color="var(--primary)" />
            </template>
            <template v-else>
              Save
            </template>
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import DaySelector from '@/components/DaySelector.vue';
import PageContentHelp from '@/components/PageContentHelp.vue';
import Spinner from '@/components/Spinner.vue';
import TransitionSlide from '@/components/TransitionSlide.vue';

export default {
  name: 'Program',
  components: {
    DaySelector,
    PageContentHelp,
    Spinner,
    TransitionSlide,
  },
  props: {
    id: { type: [String, Number], required: true },
  },
  data() {
    return {
      status: 'loading',
      pages: null,
      activePageIndex: 0,
      maxPageIndex: 0,
      leavingPageIndex: null,
      day: null,
      currentDay: null,
      dayProgress: null,
      dayToLoad: null,
      showDaySelector: false,
      videoKeySuffix: 0,
      saveInterval: null,
      saving: false,
      saveError: false,
      serverData: {},
    };
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  watch: {
    id() {
      this.status = 'loading';
      this.pages = null;
      this.activePageIndex = 0;
      this.maxPageIndex = 0;
      this.leavingPageIndex = null;
      this.day = null;
      this.currentDay = null;
      this.dayProgress = null;
      this.dayToLoad = null;
      this.showDaySelector = false;
      this.interval = null;
      this.weeks = null;
      this.videoKeySuffix = 0;
      this.saveInterval = null;
      this.saving = false;
      this.saveError = false;
      this.serverData = {};
      this.$refs = [];
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  beforeDestroy() {
    clearInterval(this.saveInterval);
    window.removeEventListener('beforeunload', this.beforeunloadHandler);
  },
  // NEED TO FIX THIS FRO PARAM CHANGE
  beforeRouteLeave(to, from, next) {
    if (
      this.status === 'loaded'
      && (this.saving || this.getSaveData().saveNeeded)
    ) {
      if (window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    beforeunloadHandler(event) {
      if (this.saving || this.getSaveData().saveNeeded) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
    calculatePercentComplete() {
      let totalCompletedItems = this.day.max_visited_page_index + 1;

      this.pages.forEach((page) => {
        page.content.forEach((pageContent) => {
          if (
            pageContent.user_response !== undefined
            && pageContent.user_response.trim() !== ''
          ) {
            totalCompletedItems += 1;
          }
        });
      });

      this.day.percent_complete = Math.round(totalCompletedItems / this.day.total_items * 100);
    },
    changeDay(newDay) {
      if (newDay !== this.day.number) {
        if (this.saving || this.getSaveData().saveNeeded) {
          if (!window.confirm(
            'You have unsaved changes. Are you sure you want to switch to a '
            + 'different day?',
          )) {
            return;
          }
        }

        this.dayToLoad = newDay;
        this.showDaySelector = false;
        this.loadData();
      } else {
        this.showDaySelector = false;
      }
    },
    changePage(direction) {
      this.leavingPageIndex = this.activePageIndex;
      const leavingPage = this.$refs[`page${this.day.number}-${this.leavingPageIndex}`][0];
      const leavingPageHasVideo = this.pages[this.leavingPageIndex].content.filter(
        c => c.content_type === 'video',
      ).length > 0;

      if (leavingPageHasVideo) {
        // Stop the video (in case it is playing)
        setTimeout(() => {
          this.videoKeySuffix += 1;
        }, 500);
      }

      let activePage;

      if (direction === 'next') {
        this.activePageIndex += 1;

        if (this.activePageIndex > this.day.max_visited_page_index) {
          this.day.max_visited_page_index = this.activePageIndex;
        }

        [activePage] = this.$refs[`page${this.day.number}-${this.activePageIndex}`];

        leavingPage.addEventListener('transitionend', () => {
          leavingPage.classList.remove('visible-page');

          activePage.addEventListener('transitionend', () => {
            this.leavingPageIndex = null;
          }, { once: true });

          activePage.classList.remove('bottom');
        }, { once: true });

        leavingPage.classList.add('top');
        activePage.classList.add('visible-page');

        if (this.activePageIndex === this.maxPageIndex) {
          this.save();
        }
      } else {
        this.activePageIndex -= 1;
        [activePage] = this.$refs[`page${this.day.number}-${this.activePageIndex}`];

        leavingPage.addEventListener('transitionend', () => {
          leavingPage.classList.remove('visible-page');
          activePage.classList.add('visible-page');

          setTimeout(() => {
            activePage.addEventListener('transitionend', () => {
              this.leavingPageIndex = null;
            }, { once: true });

            activePage.classList.remove('top');
          }, 50);
        }, { once: true });

        leavingPage.classList.add('bottom');
      }

      this.calculatePercentComplete();
    },
    getSaveData() {
      const userResponsesToSave = [];

      this.pages.forEach((page) => {
        page.content.forEach((pageContent) => {
          if (
            pageContent.user_response !== undefined
            && pageContent.user_response.trim() !== ''
            && pageContent.user_response.trim() !== this.serverData[this.day.number].userResponses[pageContent.id]
          ) {
            userResponsesToSave.push({
              id: pageContent.id,
              response: pageContent.user_response.trim(),
            });
          }
        });
      });

      if (
        userResponsesToSave.length
        || this.serverData[this.day.number].maxVisitedPageIndex !== this.day.max_visited_page_index
      ) {
        return {
          saveNeeded: true,
          saveData: {
            day: this.day.number,
            max_visited_page_index: this.day.max_visited_page_index,
            user_responses: userResponsesToSave,
          },
        };
      }

      return {
        saveNeeded: false,
      };
    },
    async loadData() {
      this.status = 'loading';

      let url = `${process.env.VUE_APP_API_URL}programs/8_weeks_to_be/`;
      url += `?enrollment=${this.id}`;

      if (this.dayToLoad) {
        url += `&day=${this.dayToLoad}`;
      }

      let response;

      try {
        response = await fetch(
          url,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${this.userData.drf_token}`,
            },
          },
        );
      } catch (e) {} // eslint-disable-line no-empty

      if (response && [200, 401].includes(response.status)) {
        if (response.status === 200) {
          const responseJson = await response.json();

          if (responseJson.no_access) {
            this.status = 'no_access';
          } else {
            this.serverData[responseJson.day.number] = {
              maxVisitedPageIndex: responseJson.day.max_visited_page_index,
              userResponses: {},
            };

            responseJson.pages.forEach((page) => {
              page.content.forEach((pageContent) => {
                if (
                  pageContent.user_response !== undefined
                  && pageContent.user_response !== ''
                ) {
                  this.serverData[responseJson.day.number].userResponses[pageContent.id] = pageContent.user_response;
                }
              });
            });

            this.day = responseJson.day;
            this.pages = responseJson.pages;
            this.maxPageIndex = this.pages.length - 1;

            this.interval = responseJson.interval;
            this.weeks = responseJson.weeks;

            if (this.day.percent_complete < 100) {
              this.activePageIndex = this.day.max_visited_page_index;
            } else {
              this.activePageIndex = 0;
            }

            if (this.dayToLoad === null) {
              this.currentDay = responseJson.current_day;
              this.dayProgress = responseJson.day_progress;
            }

            this.status = 'loaded';

            this.$nextTick(() => {
              if (this.dayToLoad === null) {
                window.addEventListener('beforeunload', this.beforeunloadHandler);
                this.saveInterval = setInterval(this.save, 60000);
              }

              this.$refs[`page${this.day.number}-${this.activePageIndex}`][0].classList.add('visible-page');

              Object.keys(this.$refs).forEach((ref) => {
                if (ref.split('-')[0] === `page${this.day.number}` && ref !== `page${this.day.number}-${this.activePageIndex}`) {
                  const pageIndex = parseInt(ref.split('-')[1], 10);
                  let classToAdd;

                  if (pageIndex > this.activePageIndex) {
                    classToAdd = 'bottom';
                  } else {
                    classToAdd = 'top';
                  }

                  this.$refs[ref][0].classList.add(classToAdd);
                }
              });
            });
          }
        } else {
          this.logUserOut();
        }
      } else {
        this.status = 'error';
      }
    },
    async save(event) {
      if (this.saving) {
        return;
      }

      this.saveError = false;

      const saveData = this.getSaveData();

      if (saveData.saveNeeded) {
        this.saving = true;
        await new Promise(resolve => setTimeout(resolve, 800));

        const postData = saveData.saveData;

        let url = `${process.env.VUE_APP_API_URL}programs/8_weeks_to_be/`;
        url += `?enrollment=${this.id}`;

        let response;

        try {
          response = await fetch(
            url,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${this.userData.drf_token}`,
              },
              body: JSON.stringify(postData),
            },
          );
        } catch (e) {} // eslint-disable-line no-empty

        this.saving = false;

        if (response && response.status === 200) {
          const responseJson = await response.json();

          if (responseJson.no_access) {
            this.status = 'no_access';
          } else {
            this.serverData[this.day.number].maxVisitedPageIndex = postData.max_visited_page_index;

            postData.user_responses.forEach((userResponse) => {
              this.serverData[this.day.number].userResponses[userResponse.id] = userResponse.response;
            });

            this.calculatePercentComplete();

            if (this.day.percent_complete === 100) {
              this.dayProgress[this.day.number] = 100;
            }
          }
        } else {
          this.saveError = true;
        }
      } else if (event) {
        this.saving = true;
        await new Promise(resolve => setTimeout(resolve, 800));
        this.saving = false;
      }

      if (event) {
        this.$router.push({ name: 'main' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .program-h1 {
    margin: 0px 0px 50px 0px;
    padding-top: 50px;
  }

  .save-error .alert {
    margin-bottom: 0;
    border-radius: 0;
  }

  .page-container {
    word-break: break-word;
    transition: .4s top ease-in-out;
  }

  .save-error + .page-container {
    top: calc(var(--header-height) + 95px);
  }

  .page {
    display: none;
    padding: 15px;
    padding-bottom: calc(var(--bottom-bar-height) + 15px);
    height: 100%;
    overflow-y: auto;
    transition: .3s all ease-in-out;

    &.top {
      transform: translateY(-100%);
      opacity: 0;
    }

    &.bottom {
      transform: translateY(100%);
      opacity: 0;
    }
  }

  .visible-page {
    display: grid;
    align-items: center;
  }

  .page-content {
    margin: 0 auto;
    width: 550px;
    max-width: calc(100vw - 30px);

    .btn {
      margin: 1rem 0;
      background-color: #f2e492;

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(211, 199, 130, 0.5);
      }

      &:hover {
        background-color: #eedc70;
      }
    }
  }

  .scale {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    justify-items: center;

    .custom-radio,
    .scale-label {
      grid-column-start: span 2;
    }
  }

  .scale-label + .scale-label {
    grid-column-end: 11;
  }

  input[type="text"],
  textarea {
    padding: 0.2rem;
    border: 0;
  }

  .bottom-bar {
    position: fixed;
    bottom: 0;
    display: grid;
    grid-gap: 0.5rem 2rem;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    padding: 1.5rem;
    width: 100%;
    height: var(--bottom-bar-height);
    background-image: linear-gradient(270deg, rgb(135, 206, 252) 0%, rgb(111, 147, 227) 53%);
    color: #fff;
    transition: .2s bottom ease-in-out;
    z-index: 2;

    &.show-day-selector:before {
      content: '';
      position: absolute;
      top: -1px;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 7px 0 7px;
      border-color: #fff transparent transparent transparent;
    }

    &.hidden {
      bottom: calc(-1 * var(--bottom-bar-height));
    }

    .btn {
      white-space: nowrap;
    }

    .btn:not(.save-button) {
      background-color: rgba(255,255,255,.15);
      color: #fff !important;

      &:not(:disabled) {
        &:focus,
        &:hover {
          background-color: rgba(255,255,255,.3);
        }
      }
    }
  }

  .progress-data {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: auto 1fr;
  }

  .progress-today {
    width: 20vw;
    min-width: 140px;
    max-width: 270px;

    .progress {
      grid-column: 1 / -1;
      background-color: rgba(255, 255, 255, 0.15);
    }
  }

  .progress-today-top {
    display: flex;
    margin-bottom: 0.5rem;
    font-size: 0.87rem;
  }

  .progress-today-percentage {
    margin-left: auto;
  }

  .day {
    all: unset;
    font-size: 2.4rem;
    line-height: 1;
    font-weight: 400;
    text-align: center;
    cursor: pointer;

    span {
      display: block;
      font-size: 0.87rem;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .nav-and-save-buttons {
    margin-left: auto;

    .btn + .btn {
      margin-left: 0.6rem;
    }
  }

  .save-button {
    width: 8.5em;
    color: var(--primary) !important;
    background-color: #fff;

    &:not(:disabled) {
      &:focus,
      &:hover {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .day-selector-container {
    position: fixed;
    bottom: var(--bottom-bar-height);
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  @media (max-width: 1199.98px) {
    .bottom-bar .btn {
      padding: 10px 6px;
      font-size: 0.8rem;
    }

    .save-button {
      width: 3.75em;
    }
  }

  @media (max-width: 767.98px) {
    .bottom-bar {
      padding: 10px;
    }

    .bottom-bar .btn span {
      display: none;
    }

    .progress-today {
      max-width: 140px;
    }

    .progress-today-top {
      font-size: 0.8rem;
    }

    .nav-and-save-buttons .btn + .btn {
      margin-left: 0.8rem;
    }
  }

  @media (max-width: 567.98px) {
    .scale-label {
      font-size: 0.75rem;
    }

    .bottom-bar {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (min-width: 375px) and (max-width: 567.98px) {
    .nav-and-save-buttons {
      grid-column: 1 / -1;
      margin-left: 0;
      text-align: center;
    }
  }

  @media (max-width: 374.98px) {
    .progress-data {
      grid-column: 1 / -1;
      grid-template-columns: 1fr 1fr;
    }

    .progress-today {
      margin-left: auto;
    }
  }
</style>
