<template>
  <div class="day-selector">
    <button
      v-for="day of days"
      :key="`day${day.num}`"
      :class="'day ' + getDayClass(day)"
      :disabled="day.time == 'future'"
      @click="$emit('dayChosen', day.num)"
    >
      {{ day.num }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'DaySelector',
  props: {
    currentDay: {
      type: Number,
      required: true,
    },
    dayProgress: {
      type: Object,
      required: true,
    },
    interval: {
      type: String,
      required: true,
    },
    weeks: {
      type: Number,
      required: true,
    },
  },
  data() {
    const days = [];

    for (let num = 1; num <= this.weeks * (this.interval === 'daily' ? 7 : 1); num += 1) {
      let time;

      if (num < this.currentDay) {
        time = 'past';
      } else if (num > this.currentDay) {
        time = 'future';
      } else {
        time = 'present';
      }

      let percentComplete;

      if (this.dayProgress[num] !== undefined) {
        percentComplete = this.dayProgress[num];
      } else {
        percentComplete = 0;
      }

      days.push({
        num,
        percentComplete,
        time,
      });
    }

    return {
      days,
    };
  },
  methods: {
    getDayClass(day) {
      let retval = `day-${day.time}`;

      if (day.time !== 'future') {
        if (day.percentComplete === 100) {
          retval += '-complete';
        } else {
          retval += '-incomplete';
        }
      }

      return retval;
    },
  },
};
</script>

<style lang="scss" scoped>
  .day-selector {
    position: relative;
    display: grid;
    grid-gap: 0.25em;
    grid-template-columns: repeat(7, 1fr);
    padding: 1.25em 1.5em;
    width: 21.25em;
    text-align: center;
    border: 1px solid #ddd;
    background-color: #fff;
    font-size: 0.8rem;
    font-weight: bold;
  }

  .day {
    padding: 0.25em;
    width: 30px;
    height: 30px;
    border: 0;
    border-radius: 50%;
    background-color: transparent;
    font-weight: inherit;
    cursor: pointer;
  }

  .day-past-complete,
  .day-present-complete {
    color: #fff;
    background-color: var(--info);
  }

  .day-past-incomplete,
  .day-present-incomplete {
    &:focus,
    &:hover {
      background-color: #efefef;
    }
  }

  .day-past-incomplete {
    color: #f00;
  }

  .day-present-incomplete {
    color: #000;
  }

  .day-future {
    color: #ddd;
    cursor: not-allowed;
  }
</style>
