<template>
  <div>
    <p v-if="pageContent.content_data.help_text_modal_content">
      <a
        v-b-modal="modalId"
        href="javascript:void(0);"
      >{{ pageContent.content_data.help_text }}</a>
    </p>
    <p
      v-else
      class="form-text text-muted"
    >
      {{ pageContent.content_data.help_text }}
    </p>

    <b-modal
      v-if="pageContent.content_data.help_text_modal_content !== ''"
      :id="modalId"
      ok-only
      ok-title="Close"
      button-size="lg"
      centered
    >
      <div v-html="pageContent.content_data.help_text_modal_content" />
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'PageContentHelp',
  props: {
    modalId: {
      type: String,
      required: true,
    },
    pageContent: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  /deep/ .modal-header {
    display: none;
  }
</style>
